<template>
  <div class="trade">
    <div class="dataSearchDelayTips">
      {{ $t("tips.dataSearchDelay") }}
    </div>
    <div class="panel-title">
      {{ $t("tradeSearch") }}
    </div>

    <div class="search-query-container">
      <div class="search-query">
        <!-- 父子企业 -->
        <el-form :form="searchForm" v-if="this.userInfo.isMasterEnterprise">
          <el-row :gutter="20">
            <el-col>
              <el-form-item :label="$t('tradeDateRange')">
                <search-date class="search-date" :data.sync="searchForm.searchDate" style="width: 100%;" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('enterprise')">
                <el-select v-model="searchForm.entList" @change="entChange" filterable clearable style="width: 100%;">
                  <el-option :label="$t('all')" value="" changed></el-option>
                  <el-option
                    v-for="item in slaveEnterprisesDatas"
                    :key="item.enterpriseCode"
                    :label="item.enterpriseName"
                    :value="item.enterpriseCode"
                  >
                    <span style="float: left">{{ item.enterpriseName }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.enterpriseCode }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('store')">
                <el-select
                  v-model="searchForm.shopList"
                  :placeholder="$t('tradeStroeHint')"
                  v-bind:multiple="notAll"
                  collapse-tags
                  @change="changeAll"
                  filterable
                  clearable
                  style="width: 100%;"
                >
                  <el-option :label="$t('all')" value="" changed></el-option>
                  <el-option-group v-for="group in shopList" :key="group.entId" :label="group.entDisplayName">
                    <el-option
                      v-for="item in group.storeList"
                      :key="item.storeId"
                      :label="item.storeName"
                      :value="item.storeId"
                    >
                    </el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('tradeKind')">
                <el-select v-model="searchForm.tradeState" style="width: 100%;">
                  <el-option :label="$t('all')" value=""></el-option>
                  <el-option :label="$t('payment')" value="SUCCESS"></el-option>
                  <el-option :label="$t('refund')" value="REFUND"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('paymentOptions')">
                <el-select
                  v-model="searchForm.tradeType"
                  multiple
                  filterable
                  clearable
                  style="width: 100%;"
                  ref="tradeType"
                  :placeholder="$t('payKindHint')"
                  @change="tradeTypeChange"
                >
                  <el-option v-for="item in tradeTypes" :key="item.category" :label="item.name" :value="item.category">
                    <div class="select-option-with-images">
                      <img class="option-image" :src="getPaymentLogos(item.name)" :alt="item.name" />
                      <span>{{ item.name }}</span>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('terminalNo')">
                <el-input clearable v-model="searchForm.deviceNo" :placeholder="$t('terminalHint')"></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('tradNo')">
                <el-input clearable v-model="searchForm.tradeRefvNo" :placeholder="$t('tradNoHint')"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <!-- 非父子企业 isMasterEnterprise: false-->
        <el-form :form="searchForm" v-else>
          <el-row :gutter="20">
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('tradeDateRange')">
                <search-date class="search-date" :data.sync="searchForm.searchDate" style="width: 100%;" />
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('tradeKind')">
                <el-select v-model="searchForm.tradeState" style="width: 100%;">
                  <el-option :label="$t('all')" value=""></el-option>
                  <el-option :label="$t('payment')" value="SUCCESS"></el-option>
                  <el-option :label="$t('refund')" value="REFUND"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('store')">
                <el-select
                  v-if="$settings.newStoreVersion === true"
                  v-model="searchForm.shopList"
                  :placeholder="$t('tradeStroeHint')"
                  v-bind:multiple="notAll"
                  @change="changeAll"
                  filterable
                  clearable
                  style="width: 100%;"
                >
                  <el-option :label="$t('all')" value="" changed></el-option>
                  <el-option v-for="item in shopList" :key="item.storeId" :label="item.storeName" :value="item.storeId">
                  </el-option>
                </el-select>
                <el-select
                  v-else
                  v-model="searchForm.shopList"
                  :placeholder="$t('tradeStroeHint')"
                  v-bind:multiple="notAll"
                  @change="changeAll"
                  filterable
                  clearable
                  style="width: 100%;"
                >
                  <el-option :label="$t('all')" value="" changed></el-option>
                  <el-option-group v-for="group in shopList" :key="group.label" :label="group.label">
                    <el-option
                      v-for="item in group.options"
                      :key="item.shopId"
                      :label="item.shopName"
                      :value="item.shopId"
                    >
                    </el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('terminalNo')">
                <el-input clearable v-model="searchForm.deviceNo" :placeholder="$t('terminalHint')"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('tradNo')">
                <el-input clearable v-model="searchForm.tradeRefvNo" :placeholder="$t('tradNoHint')"></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('paymentOptions')">
                <el-select
                  v-model="searchForm.tradeType"
                  multiple
                  filterable
                  clearable
                  style="width: 100%;"
                  ref="tradeType"
                  :placeholder="$t('payKindHint')"
                  @change="tradeTypeChange"
                >
                  <el-option v-for="item in tradeTypes" :key="item.category" :label="item.name" :value="item.category">
                    <div class="select-option-with-images">
                      <img class="option-image" :src="getPaymentLogos(item.name)" :alt="item.name" />
                      <span>{{ item.name }}</span>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="search-btn">
          <el-button class="cancel" @click="resetSearchQuery">{{ $t("searchReset") }}</el-button>
          <rating-popover
            :defaultVisible="false"
            rateTitle="機能改善、サービス品質向上のためにアンケートにご協力お願いします。"
            :rateData="[
              {
                title: '取引管理機能の満足度を星1つ（かなり不満）から星5つ（大満足）の範囲で教えてください。',
                type: 'star',
                platform: 'StarPay-Works',
                entCode: $store.state.app.userInfo.entName,
                entName: $store.state.app.userInfo.entDisplayName,
                userName: $store.state.app.userInfo.userName,
                oem: $settings.oem
              }
            ]"
            placement="bottom"
            :showFrequency="userInfo.isMasterEnterprise ? 'never' : $settings.oem == 'netstars' ? 'once' : 'never'"
            @rate="handleRating"
          >
            <el-button class="confirm" @click="getList(1)" :disabled="loading">{{ $t("search") }}</el-button>
          </rating-popover>
        </div>
      </div>

      <div class="summary-container">
        <div class="summary-title">{{ $t("tradeDataByDateRange") }}</div>

        <el-row :gutter="20">
          <el-col :lg="6" :md="12" :xs="12" :sm="12" class="summary-item">
            <div class="key">{{ $t("tradeCount") }}</div>
            <div class="value">{{ numFormat(total) }} {{ $t("passage") }}</div>
          </el-col>
          <el-col :lg="6" :md="12" :xs="12" :sm="12" class="summary-item">
            <div class="key">{{ $t("tradeTotalPayAmount") }}</div>
            <div class="value">{{ moneyFormat(totalPayAmount) }}</div>
          </el-col>
          <el-col :lg="6" :md="12" :xs="12" :sm="12" class="summary-item">
            <div class="key">{{ $t("tradeTotalRefundAmount") }}</div>
            <div class="value" style="color: #FF3F4D;">{{ moneyFormat(totalRefundAmount) }}</div>
          </el-col>

          <el-col :lg="6" :md="12" :xs="12" :sm="12" class="summary-item">
            <div class="key">{{ $t("tradeDeductionAmount") }}</div>
            <div class="value">{{ moneyFormat(dValue) }}</div>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="panel-sub-title">
      <div class="title">{{ total }} {{ $t("tradeCountPay") }}({{ beginCount }}~{{ endCount }})</div>

      <el-button
        class="cancel"
        :loading="btnLoading"
        :disabled="btnLoading"
        @click="downloadClick"
        icon="el-icon-download"
        v-permission="'Record.RecordDownload'"
      >
        {{ $t("recordDownload") }}
      </el-button>
    </div>

    <div class="result-background">
      <div class="result-table">
        <el-table
          :data="tradeData"
          :row-class-name="rowClassName"
          stripe
          border
          v-loading="loading"
          @row-click="toDetail"
          @cell-mouse-enter="handleCellMouseEnter"
          @cell-mouse-leave="handleCellMouseLeave"
          :span-method="handleObjectSpanMethod"
        >
          <el-table-column prop="endTime" :label="$t('dateTime')" min-width="220">
            <template slot-scope="scope">
              <!-- <i class="el-icon-time"></i> -->
              <span style="margin-left: 5px">{{ dateFormat(scope.row.endTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="tradeRefvNo" :label="$t('tradNo')" min-width="210"></el-table-column>
          <el-table-column prop="tradeTypeDisplayName" :label="$t('tradePayKind')" width="220">
            <template slot-scope="scope">
              <div class="image-text-container">
                <img :src="getPaymentLogos(scope.row.tradeTypeDisplayName)" :alt="scope.row.tradeTypeDisplayName" />
                <span>{{ scope.row.tradeTypeDisplayName }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="tradeState" :label="$t('tradeKind')" width="170">
            <template slot-scope="scope">
              {{ scope.row.tradeState === "REFUND" ? $t("refund") : $t("payment") }}
            </template>
          </el-table-column>
          <el-table-column prop="shopName" :label="$t('store')" width="210"></el-table-column>
          <el-table-column prop="deviceNo" :label="$t('terminalNo')" width="210"></el-table-column>
          <el-table-column prop="orderAmount" :label="$t('cashCount')" min-width="150">
            <template slot-scope="scope">{{ moneyFormat(Number(scope.row.orderAmount)) }} </template>
          </el-table-column>
          <el-table-column
            :label="$t('combinationPaymentTotalAmount')"
            min-width="150"
            align="center"
            v-if="$settings.oem === 'KANAGAWA'"
          >
            <template slot-scope="scope">{{ moneyFormat(Number(scope.row.combinationPaymentTotalAmount)) }} </template>
          </el-table-column>
          <el-table-column prop="remark" :label="$t('remark')" min-width="150"></el-table-column>
          <el-table-column fixed="right" width="120" :label="$t('operation')">
            <template slot-scope="scope">
              <div style="text-align: center;">
                <el-button size="mini" type="text" @click.native.stop="toDetail(scope.row)">
                  {{ $t("detail") }}
                  <i class="el-icon-arrow-right"></i>
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-pagination
        background
        layout=" prev, pager, next, sizes, total"
        :total="total"
        :page-size="currentPageSize"
        :page-sizes="[10, 15, 20]"
        :current-page="currentPage"
        @current-change="currentChange"
        @size-change="sizeChange"
        class="common-pagination"
      >
      </el-pagination>
    </div>

    <side-layer :show-side-layer.sync="detailDrawer">
      <div v-loading="detailLoading">
        <div class="setting-nav-title">{{ $t("tradeInfo") }}</div>
        <div class="dataTable">
          <div class="dataItem">
            <div class="name">{{ $t("payDateTime") }}</div>
            <div class="value">
              {{ dateFormat(detailInfo.endTime) }}
            </div>
          </div>

          <div class="dataItem">
            <div class="name">{{ $t("enterpriseCode") }}</div>
            <div class="value">
              {{ detailInfo.entId }}
            </div>
          </div>
          <div class="dataItem">
            <td class="name">{{ $t("storeCode") }}</td>
            <td class="value">
              {{ detailInfo.shopId }}
            </td>
          </div>
          <div class="dataItem">
            <td class="name">{{ $t("storeName") }}</td>
            <td class="value">
              {{ detailInfo.shopDisplay }}
            </td>
          </div>
          <div class="dataItem" v-if="!detailInfo.combinationPayment">
            <td class="name">{{ $t("terminalNo") }}</td>
            <td class="value">
              {{ detailInfo.deviceNo }}
            </td>
          </div>
          <div class="dataItem" v-if="detailInfo.combinationPayment">
            <td class="name">{{ $t("combinationPaymentTotalAmount") }}</td>
            <td class="value">
              {{ moneyFormat(detailInfo.combinationPaymentTotalAmount) }}
            </td>
          </div>
          <div class="dataItem">
            <td class="name">
              {{ $t("tradeKind") }}
            </td>
            <td class="value">
              {{ $t("payment") }}
            </td>
          </div>
          <div class="dataItem">
            <td :class="['name', detailInfo.combinationPayment ? 'solid' : '']">
              {{ $t("tradePayKind") }}
            </td>
            <td :class="['value', detailInfo.combinationPayment ? 'solid' : '']">
              <div class="image-text-container">
                <img :src="getPaymentLogos(detailInfo.tradeTypeDisplayName)" :alt="detailInfo.tradeTypeDisplayName" />
                <span>{{ detailInfo.tradeTypeDisplayName }} </span>
              </div>
            </td>
          </div>
          <div class="dataItem">
            <td class="name">
              {{ $t("cashCount") }}
            </td>
            <td class="value">
              {{ moneyFormat(Number(detailInfo.orderAmount)) }}
            </td>
          </div>
          <div class="dataItem">
            <td class="name">{{ $t("paymentNo") }}</td>
            <td class="value">
              {{ detailInfo.tradeNo }}
            </td>
          </div>
          <div class="dataItem">
            <td class="name">{{ $t("outTradeNo") }}</td>
            <td class="value">
              {{
                detailInfo.tradeCategory === 5 || detailInfo.tradeCategory === 6
                  ? detailInfo.orderNo
                  : detailInfo.outTradeNo
              }}
            </td>
          </div>
          <div class="dataItem">
            <td class="name">{{ $t("otherWalletNumber") }}</td>
            <td class="value">
              {{ detailInfo.otherWalletNumber }}
            </td>
          </div>
          <div class="dataItem" v-if="detailInfo.showCreditCardNo">
            <td class="name">クレジットカード番号</td>
            <td class="value">{{ detailInfo.creditCardNo }}</td>
          </div>
          <div class="dataItem" v-if="detailInfo.tradeTypeDisplayName === 'CoGCa'">
            <td class="name">{{ $t("deviceIdentificationNumber") }}</td>
            <td class="value">
              {{ detailInfo.deviceIdentificationNumber }}
            </td>
          </div>
          <div class="dataItem" v-if="detailInfo.tradeTypeDisplayName === 'CoGCa'">
            <td class="name">{{ $t("deviceProcessingNumber") }}</td>
            <td class="value">
              {{ detailInfo.deviceProcessingNumber }}
            </td>
          </div>

          <div v-for="item in detailInfo.combinationRecords" v-bind:key="item.outTradeNo">
            <div class="dataItem">
              <td :class="['name', detailInfo.combinationPayment ? 'solid' : '']">
                {{ $t("tradePayKind") }}
              </td>
              <td :class="['value', detailInfo.combinationPayment ? 'solid' : '']">
                <div class="image-text-container">
                  <img :src="getPaymentLogos(item.tradeTypeDisplayName)" :alt="item.tradeTypeDisplayName" />
                  <span>{{ item.tradeTypeDisplayName }} </span>
                </div>
              </td>
            </div>
            <div class="dataItem">
              <td class="name">
                {{ $t("cashCount") }}
              </td>
              <td class="value">
                {{ moneyFormat(Number(item.orderAmount)) }}
              </td>
            </div>
            <div class="dataItem">
              <td class="name">{{ $t("paymentNo") }}</td>
              <td class="value">
                {{ item.tradeNo }}
              </td>
            </div>
            <div class="dataItem">
              <td class="name">{{ $t("outTradeNo") }}</td>
              <td class="value">
                {{ item.outTradeNo }}
              </td>
            </div>
          </div>
          <div class="dataItem" v-if="!detailInfo.combinationPayment">
            <td class="name">
              {{ $t("printCount") }}
            </td>
            <td class="value">
              {{ detailInfo.payPrintTimes }}
            </td>
          </div>
          <div class="dataItem">
            <td class="name">
              {{ $t("remark") }}
              <el-button
                type="danger"
                icon="el-icon-edit"
                circle
                size="mini"
                v-if="detailInfo.tradeCategory == 7"
                @click="editRemark"
              ></el-button>
            </td>
            <td class="value">
              <pre>{{ detailInfo.remark }}</pre>
            </td>
          </div>
        </div>

        <div class="setting-nav-title" v-if="detailInfo.refundRecordList && detailInfo.refundRecordList.length > 0">
          {{ $t("tradeRefundInfo") }}
        </div>

        <div v-for="item in detailInfo.refundRecordList" :key="item.recordId" style="margin-bottom: 20px;">
          <div class="dataTable">
            <div class="dataItem">
              <td class="name">
                {{ $t("refundDate") }}
              </td>
              <td class="value">
                {{ dateFormat(item.beginTime) }}
              </td>
            </div>
            <div class="dataItem">
              <td class="name">
                {{ $t("terminalNo") }}
              </td>
              <td class="value">
                {{ item.deviceId }}
              </td>
            </div>
            <div class="dataItem">
              <td class="name">
                {{ $t("refundNo") }}
              </td>
              <td class="value">
                {{ item.refundNo }}
              </td>
            </div>
            <div class="dataItem">
              <td class="name">{{ $t("tradeKind") }}</td>
              <td class="value">
                {{ $t("refund") }}
              </td>
            </div>
            <div class="dataItem">
              <td class="name">
                {{ $t("cashCount") }}
              </td>
              <td class="value">{{ moneyFormat(item.refundAmount) }}</td>
            </div>
          </div>
        </div>
        <div
          class="explain"
          v-if="detailInfo.creditTradeStatus === 'S00000_35' || detailInfo.creditTradeStatus === 'S00000_33'"
        >
          <p style="color:#ec1919;">返金中です。しばらくお待ちください。</p>
        </div>

        <div v-permission="'Refund.Refund'">
          <div v-if="detailInfo.canRefund">
            <div class="explain">
              {{ $t("tradeRefundButtonInfo") }}
            </div>
            <el-button class="delete" style="margin-top: 20px;" @click="toRefund">
              {{ $t("tradeRefund") }}
            </el-button>
          </div>
        </div>
      </div>
    </side-layer>

    <modal-dialog :show-dialog.sync="refundDialog" :dialog-title="$t('tradeRefund')">
      <div class="explain" style="text-align: center;">
        <p>{{ $t("tradeRefundSettingInfo") }}</p>
        <p>{{ $t("refundDelayTips") }}</p>
      </div>

      <el-form label-position="top" class="refund-form" @submit.native.prevent>
        <el-form-item v-bind:label="$t('tradeRefundMoney')">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-radio-group v-model="refundType" @change="handleRefundTypeChange" class="refund-radio">
                <el-radio label="all" name="all">{{ $t("cashAll") }}</el-radio>
                <el-radio label="part" name="part" v-if="canPartRefund">
                  {{ $t("tradeCountSet") }}
                </el-radio>
              </el-radio-group></el-col
            >
            <el-col :span="12"
              ><el-input
                type="number"
                class="refund-part-input"
                v-if="canPartRefund"
                @change="refundAmountChange"
                v-model="refundAmount"
                :controls="false"
                :max="canRefundAmount"
                :min="0"
                :precision="refundAmountPrecision"
                :placeholder="$t('PleaseEnterRefundAmount')"
                :disabled="refundType != 'part'"
              >
              </el-input
            ></el-col>
          </el-row>
        </el-form-item>

        <el-form-item :label="$t('refundDetail')">
          <table cellspacing="0" cellpadding="0" border="0" class="refund-table">
            <tr>
              <th>{{ $t("tradePaidCount") }}</th>
              <th>{{ $t("tradeRefundedCount") }}</th>
            </tr>

            <tr>
              <td>{{ moneyFormat(canRefundAmount) }}</td>
              <td>
                {{ refundType == "all" ? moneyFormat(canRefundAmount) : moneyFormat(refundAmount) }}
              </td>
            </tr>
          </table>
        </el-form-item>
      </el-form>

      <template v-slot:footer>
        <el-button class="cancel" :disabled="refundLoading" @click="refundDialog = false">
          {{ $t("cancel") }}
        </el-button>

        <el-button
          class="confirm dialog-btn"
          type="primary"
          @click="refundSubmit"
          :loading="refundLoading"
          :disabled="refundLoading"
        >
          {{ $t("tradeChangeApply") }}
        </el-button>
      </template>
    </modal-dialog>
    <modal-dialog :show-dialog.sync="downloadDialog" :dialog-title="$t('dataDownload')" :show-footer="false">
      <div class="download-type-container">
        <div class="download-type-item" @click="downloadTradeData(3)">
          <img src="~@/assets/download_type_icon/csv.svg" alt="" />
          <span>CSV</span>
        </div>
        <div class="download-type-item" @click="downloadTradeData(0)">
          <img src="~@/assets/download_type_icon/excel.svg" alt="" />
          <span>Excel</span>
        </div>

        <div class="download-type-item" @click="downloadTradeData(1)">
          <img src="~@/assets/download_type_icon/pdf.svg" alt="" />
          <span>PDF</span>
        </div>

        <div class="download-type-item" @click="downloadTradeData(2)">
          <img src="~@/assets/download_type_icon/zip.svg" alt="" />
          <span>ZIP</span>
        </div>
      </div>
      <div class="download-is-check">
        <p style="margin-bottom:10px">{{ $t("containRemarkTip") }}</p>
        <div>
          <el-checkbox v-model="containRemark" @change="containRemarkChange">
            {{ $t("containRemark") }}
          </el-checkbox>
        </div>
        <div style="margin-top:10px" v-if="$settings.hasClickHouse === true">
          <el-checkbox v-model="containBankType" @change="containBankTypeChange">
            {{ $t("containBankType") }}
          </el-checkbox>
        </div>
        <div style="margin-top:10px" v-if="$settings.showPaymentMethod === true">
          <el-checkbox v-model="containPaymentMethod" @change="containPaymentMethodChange">
            <template>
              {{ $t("containPaymentMethod") }}
            </template>
          </el-checkbox>
        </div>
      </div>
    </modal-dialog>
    <modal-dialog :show-dialog.sync="editRemarkDialog" :dialog-title="$t('editRemark')">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" @submit.native.prevent>
        <el-form-item :label="$t('remark')" prop="remark">
          <el-input v-model="ruleForm.remark" maxlength="100" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <template v-slot:footer>
        <el-button class="cancel" :disabled="editRemarkLoading" @click="editRemarkDialog = false">
          {{ $t("cancel") }}
        </el-button>
        <el-button
          class="confirm dialog-btn"
          type="primary"
          @click="editRemarkSubmit"
          :loading="editRemarkLoading"
          :disabled="editRemarkLoading"
        >
          {{ $t("confirm") }}
        </el-button>
      </template>
    </modal-dialog>
  </div>
</template>

<script>
import pagination from "@/mixins/pagination";
import getPaymentLogos from "@/mixins/paymentLogos";

import {
  recordList,
  recordDetail,
  tradeTypes,
  refund,
  downloadTradeFile,
  editRemark,
  enterprisesRecordList,
  enterprisesRecordDetail,
  downloadEnterprisesRecordsFile,
  multiTradeTypes
} from "api/trade";
import { shopList, storeList, slaveenterprisestorelist } from "api/shop";
import { slaveEnterprises } from "api/enterprise";
import { isArray, isUndefined } from "lodash";
import { getSessionStorage } from "@/utils/storage";
import { moneyFormat, numFormat } from "utils";
import { executeDownload } from "utils/download";
import { dateFormat, diffMonths } from "utils/date";
import { setLocalStorage, getLocalStorage } from "@/utils/storage";
export default {
  mixins: [pagination, getPaymentLogos],

  data() {
    var validate = (rule, value, callback) => {
      if (!value.trim()) {
        callback(new Error(this.$t("remarkIsNull")));
      } else {
        callback();
      }
    };
    return {
      slaveEnterprisesDatas: [],
      userInfo: {},
      tradeData: [],
      spanData: [],
      loading: false,
      btnLoading: false,
      totalPayAmount: 0,
      totalRefundAmount: 0,
      shopList: [],
      tradeTypes: [],
      searchForm: {
        searchDate: [
          this.moment()
            .startOf("day")
            .format("YYYY/MM/DD HH:mm:ss"),
          this.moment()
            .endOf("day")
            .format("YYYY/MM/DD HH:mm:ss")
        ],
        shopList: "",
        tradeType: [],
        tradeState: "",
        deviceNo: "",
        tradeRefvNo: "",
        entList: ""
      },
      downloadDialog: false,
      editRemarkDialog: false,
      editRemarkLoading: false,
      detailDrawer: false,
      detailLoading: false,
      detailInfo: {},
      refundDialog: false,
      canRefundAmount: 0,
      refundAmount: "",
      refundType: "all",
      refundAmountPrecision: 0,
      canPartRefund: true,
      canEditRefundFee: false,
      refundLoading: false,
      notAll: false,
      beginCount: 0,
      endCount: 0,
      currentHoverRowId: [],
      containRemark: false,
      containBankType: false,
      containPaymentMethod: false,
      ruleForm: {
        remark: ""
      },
      rules: {
        remark: [
          { required: true, message: this.$t("remarkIsNull"), trigger: "blur" },
          { min: 1, max: 100, message: "长度在 3 到 5 个字符", trigger: "blur" },
          { validator: validate, trigger: ["blur", "change"] }
        ]
      }
    };
  },

  methods: {
    handleRating({ feature, rating }) {
      console.log(`收到的评分: ${rating} 分, 来自 ${feature}`);
      // 这里可以加入评分后的其他处理逻辑
    },
    dateFormat,
    numFormat,
    diffMonths,
    moneyFormat,
    setLocalStorage,
    getLocalStorage,
    slaveEnterprises,
    tradeTypeChange() {
      let isMobile = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );

      this.$nextTick(() => {
        if (isMobile) this.$refs["tradeType"].blur();
      });
    },

    changeAll(value) {
      if (!value[value.length - 1]) {
        this.searchForm.shopList = "";
        this.notAll = false;
      } else {
        if (isArray(value)) {
          this.searchForm.shopList = value;
        } else {
          this.searchForm.shopList = [value];
        }
        this.notAll = true;
      }
      if (this.userInfo.isMasterEnterprise) {
        this.getMultiTradeTypes();
      }
    },
    // 交易查询
    getList(pageIndex = 1, pageSize = this.currentPageSize) {
      let that = this;
      let beginTime = this.searchForm.searchDate[0];
      let endTime = this.searchForm.searchDate[1];
      this.currentPage = pageIndex;
      if (diffMonths(endTime, beginTime) > 2) {
        this.$message.warning(this.$t("tradeSearchPeriodLimit"));
      } else {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(that.searchForm));
        delete data.searchDate;
        if (!isArray(data.shopList)) {
          data.shopList = [];
        }
        let action = this.userInfo.isMasterEnterprise ? enterprisesRecordList : recordList;
        action({
          pageIndex,
          pageSize,
          beginTime,
          endTime,
          ...data
        }).then(response => {
          this.loading = false;
          if (response.statusCode === 200 && response.resultStatus === "Success") {
            this.tradeData = response.result.records;
            this.getSpanData(this.tradeData);
            this.total = response.result.totalCount;
            this.totalRefundAmount = response.result.totalRefundAmount;
            this.totalPayAmount = response.result.totalPayAmount;
            if (response.result.records.length) {
              that.beginCount = (pageIndex - 1) * pageSize + 1;
              that.endCount = that.beginCount + response.result.records.length - 1;
            }
          } else {
            this.tradeData = [];
            this.total = 0;
            this.totalRefundAmount = 0;
            this.totalPayAmount = 0;
            that.beginCount = 0;
            that.endCount = 0;
          }
        });
      }
    },
    // 计算需要合并的单元格
    getSpanData(data) {
      // 存放计算好的合并单元格的规则
      this.spanData = [];
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanData.push(1);
          this.pos = 0;
        } else {
          // 判断当前元素与上一个元素是否相同
          if (data[i].groupId != 0 && data[i - 1].groupId != 0 && data[i].groupId === data[i - 1].groupId) {
            this.spanData[this.pos] += 1;
            this.spanData.push(0);
          } else {
            this.spanData.push(1);
            this.pos = i;
          }
        }
      }
    },

    handleCellMouseEnter(row) {
      row.groupId !== "0"
        ? (this.currentHoverRowId = this.tradeData.filter(item => item.groupId === row.groupId))
        : (this.currentHoverRowId = []);
    },

    handleCellMouseLeave() {
      this.currentHoverRowId = [];
    },

    // 合并单元格
    handleObjectSpanMethod({ rowIndex, columnIndex }) {
      // 需要合并的列
      // [0, 1, 2].includes(columnIndex ), 表示合并前三列
      if ([7, 8].includes(columnIndex)) {
        const _row = this.spanData[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col
        };
      }
      if (columnIndex === 0) {
        const _row = this.spanData[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col
        };
      }
    },
    resetSearchQuery() {
      this.searchForm = {
        searchDate: [
          this.moment()
            .startOf("day")
            .format("YYYY/MM/DD HH:mm:ss"),
          this.moment()
            .endOf("day")
            .format("YYYY/MM/DD HH:mm:ss")
        ],
        shopList: "",
        entList: "",
        tradeType: [],
        tradeState: "",
        deviceNo: "",
        tradeRefvNo: ""
      };
      this.notAll = false;
      if (this.userInfo.isMasterEnterprise) {
        this.getSlaveEnterprises();
        this.getSlaveEnterpriseStoreList();
        this.getMultiTradeTypes();
      }
    },

    toDetail(row) {
      this.detailDrawer = true;
      this.detailLoading = true;
      this.detailInfo = {};
      let { orderNo, outTradeNo, tradeType } = row;
      // if (row.isCreditCard) {
      //   outTradeNo = orderNo;
      // }
      let action = this.userInfo.isMasterEnterprise ? enterprisesRecordDetail : recordDetail;
      action({ outTradeNo, tradeType, orderNo }).then(response => {
        this.detailLoading = false;
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          this.detailInfo = response.result;
          if (this.detailInfo.combinationPayment) {
            this.canRefundAmount = Number(this.detailInfo.combinationPaymentTotalAmount);
          } else {
            this.canRefundAmount = Number(this.detailInfo.orderAmount);
          }
          if (isArray(this.detailInfo.refundRecordList)) {
            let allAmount = 0;
            this.detailInfo.refundRecordList.forEach(item => {
              allAmount += Number(item.refundAmount);
            });
            if (this.detailInfo.combinationPayment) {
              this.canRefundAmount = Number(this.detailInfo.combinationPaymentTotalAmount) - Number(allAmount);
            } else {
              this.canRefundAmount = Number(this.detailInfo.orderAmount) - Number(allAmount);
            }
          }
        }
      });
    },

    refundAmountChange(value) {
      console.log("refundAmount", value);
      isUndefined(value) ? (this.refundAmount = 0) : (this.refundAmount = value);
      this.refundAmount = this.fixNumber(this.refundAmount, this.pricePrecision);
    },

    handleRefundTypeChange(e) {
      console.log("refundType", e);

      if (e === "part") {
        this.refundAmount = "";
        const inputElement = this.$el.querySelector(".refund-part-input input");
        if (inputElement) {
          inputElement.focus();
        }
      } else {
        this.refundAmount = "";
      }
    },
    //编辑CPM订单备注
    editRemark() {
      this.ruleForm.remark = this.detailInfo.remark;
      this.editRemarkDialog = true;
    },
    editRemarkSubmit() {
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          this.editRemarkLoading = true;
          let data = { outTradeNo: this.detailInfo.outTradeNo, remark: this.ruleForm.remark };
          editRemark(data).then(response => {
            if (response.statusCode === 200 && response.resultStatus === "Success") {
              this.$message({
                showClose: true,
                message: this.$t("success"),
                type: "success"
              });
              this.editRemarkDialog = false;
              this.toDetail(this.detailInfo);
              this.getList(this.currentPage);
            }
            this.editRemarkLoading = false;
          });
        }
      });
    },

    toRefund() {
      this.refundAmount = "";
      this.refundType = "all";
      if (this.detailInfo.tradeCategory == 6) {
        this.$message.warning(this.$t("unsupportedRefund"));
        return;
      }
      this.refundDialog = true;
      if (this.detailInfo.onlyAllRefund) {
        this.canPartRefund = false;
      } else {
        if (this.detailInfo.canPartialRefund) {
          this.canPartRefund = true;
        } else {
          this.canPartRefund = false;
        }
      }
      switch (getSessionStorage("currency")) {
        case "JPY":
          this.refundAmountPrecision = 0;
          break;
        default:
          this.refundAmountPrecision = 2;
          break;
      }
    },
    validateRefundAmount(refundAmount) {
      console.log("validate", refundAmount);
      // 检查refundAmount是否是数字类型
      // if (typeof refundAmount !== "number") {
      //   return false;
      // }

      // 检查refundAmount是否大于0
      console.log("检查refundAmount是否大于0");
      if (refundAmount <= 0) {
        return false;
      }
      console.log(true);

      // 检查refundAmount是否为整数\

      if (this.refundAmountPrecision === 0) {
        console.log("检查refundAmount是否为整数");
        if (!Number.isInteger(refundAmount)) {
          return false;
        }
        console.log(true);
      }

      console.log("检查refundAmount是否小于等于可退款金额");
      // 检查refundAmount是否小于等于可退款金额
      if (refundAmount > this.canRefundAmount) {
        return false;
      }
      console.log(true);

      return true;
    },

    refundSubmit() {
      let data = {
        outTradeNo: this.detailInfo.outTradeNo,
        shopId: this.detailInfo.shopId,
        orderNo: this.detailInfo.orderNo
      };
      if (this.refundType === "all") {
        data.refundFee = this.canRefundAmount.toString();
      } else {
        if (!this.validateRefundAmount(this.refundAmount)) {
          this.$message.error(this.$t("refundFeeError"));
          return;
        }
        data.refundFee = this.refundAmount.toString();
      }
      this.refundLoading = true;
      refund(data).then(response => {
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          let msg = this.$t("success");

          this.$message({
            showClose: true,
            message: msg,
            type: "success"
          });
          this.refundDialog = false;
          this.toDetail(this.detailInfo);
          this.getList(this.currentPage);
        } else {
          if (response.resultType === 4008) {
            let msg = this.$t("refundDelay");

            this.$message({
              showClose: true,
              message: msg,
              type: "success"
            });
            this.refundDialog = false;
            this.toDetail(this.detailInfo);
            this.getList(this.currentPage);
          }
        }
        this.refundLoading = false;
      });
    },

    rowClassName({ row }) {
      let className = "";

      if (row.tradeState === "REFUND") className += "row-refund-color";

      this.currentHoverRowId.some(item => item.groupId === row.groupId) ? (className += " group-hover-row") : "";

      return className;
    },

    getShopList() {
      if (this.$settings.newStoreVersion === true) {
        storeList().then(response => {
          if (response.statusCode === 200 && response.resultStatus === "Success") {
            this.shopList = response.result;
          }
        });
      } else {
        shopList().then(response => {
          if (response.statusCode === 200 && response.resultStatus === "Success") {
            this.shopList = [
              {
                label: this.$t("shopOffline"),
                options: []
              },
              {
                label: this.$t("shopOnline"),
                options: []
              }
            ];

            if (response.result.length > 0) {
              response.result.forEach(item => {
                if (item.isOnline) {
                  this.shopList[1].options.push(item);
                } else {
                  this.shopList[0].options.push(item);
                }
              });
            }
          }
        });
      }
    },

    getTradeTypes() {
      tradeTypes().then(response => {
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          this.tradeTypes = response.result;
        }
      });
    },
    // 查询多企业多店铺支付方式列表
    getMultiTradeTypes() {
      let param = { entIdList: this.searchForm.entList, storeIdList: this.searchForm.shopList };
      multiTradeTypes(param).then(response => {
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          this.tradeTypes = response.result;
        }
      });
    },
    containRemarkChange(value) {
      this.setLocalStorage("containRemark", value);
    },
    containBankTypeChange(value) {
      this.setLocalStorage("containBankType", value);
    },
    containPaymentMethodChange(value) {
      this.setLocalStorage("containPaymentMethod", value);
    },
    downloadClick() {
      this.downloadDialog = true;
      this.containRemark =
        this.getLocalStorage("containRemark") != null && this.getLocalStorage("containRemark") === "true"
          ? Boolean(this.getLocalStorage("containRemark"))
          : false;
      this.containBankType =
        this.getLocalStorage("containBankType") != null && this.getLocalStorage("containBankType") === "true"
          ? Boolean(this.getLocalStorage("containBankType"))
          : false;

      this.containPaymentMethod =
        this.getLocalStorage("containPaymentMethod") != null && this.getLocalStorage("containPaymentMethod") === "true"
          ? Boolean(this.getLocalStorage("containPaymentMethod"))
          : false;
    },
    // 下载交易记录
    downloadTradeData(fileType) {
      this.downloadDialog = false;
      this.btnLoading = true;
      let action = this.userInfo.isMasterEnterprise ? downloadEnterprisesRecordsFile : downloadTradeFile;
      action({
        BeginTime: this.searchForm.searchDate[0],
        EndTime: this.searchForm.searchDate[1],
        EntList: this.searchForm.entList,
        ShopList: this.searchForm.shopList,
        TradeType: this.searchForm.tradeType,
        TradeState: this.searchForm.tradeState,
        DeviceNo: this.searchForm.deviceNo,
        TradeRefvNo: this.searchForm.tradeRefvNo,
        fileFormat: fileType,
        containRemark: this.containRemark,
        containBankType: this.containBankType,
        containPaymentMethod: this.containPaymentMethod
      }).then(response => {
        if (response.data) {
          executeDownload(response);
          this.btnLoading = false;
          this.$message.success(this.$t("success"));
        } else {
          this.btnLoading = false;
        }
      });
    },
    // 获取父子企业数据
    getSlaveEnterprises() {
      slaveEnterprises().then(response => {
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          this.slaveEnterprisesDatas = response.result;
        } else {
          this.slaveEnterprisesDatas = [];
        }
      });
    },
    // 获取子企业店铺
    getSlaveEnterpriseStoreList(entList) {
      slaveenterprisestorelist({ entList }).then(response => {
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          this.shopList = response.result;
        }
      });
    },
    // 企业选择事件
    entChange(value) {
      this.searchForm.shopList = "";
      this.notAll = false;
      if (!value[value.length - 1]) {
        this.getSlaveEnterpriseStoreList();
      } else {
        this.getSlaveEnterpriseStoreList(value);
      }
      this.getMultiTradeTypes();
    },
    fixNumber(input, decimalPlaces) {
      let number = parseFloat(input);
      if (isNaN(number)) {
        console.log("Invalid input"); // 或者其他的错误处理
        return false;
      }
      return Number(number.toFixed(decimalPlaces));
    }
  },

  computed: {
    dValue() {
      return Number(this.totalRefundAmount) + Number(this.totalPayAmount);
    }
  },

  mounted() {
    this.userInfo = JSON.parse(getSessionStorage("userInfo"));
    if (this.userInfo.isMasterEnterprise) {
      this.getSlaveEnterprises();
      this.getSlaveEnterpriseStoreList();
      this.getMultiTradeTypes();
    } else {
      this.getShopList();
      this.getTradeTypes();
    }
    this.getList();
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
input[type="number"] {
  -moz-appearance: textfield !important;
}

.table-div {
  display: flex;
  flex-direction: column;
}

.row-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.name-div,
.value-div {
  flex-basis: 50%;
}
.selected {
  &:after {
    top: 0 !important;
  }
}
// .select-option-with-images {
//   @include flex(flex-start);
//   .option-image {
//     height: 36px;
//     margin-right: 10px;
//   }
// }
.download-is-check {
  width: 80%;
  padding-top: 3%;
  padding-left: 8%;
  // margin: -30px auto 20px;
  .el-checkbox__label {
    font-size: 18px;
  }
  p {
    margin: 10px 0px 20px;
    font-size: 12px;
  }
}
// pre {
//   font-size: 16px;
//   font-family: Microsoft YaHei;
//   white-space: pre-wrap;
// }
</style>
